.App {
	align-items: center;
	background-color: #6ca6c1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	min-height: 100vh;
	overflow: hidden;
}

.App-header {
	height: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	max-width: 700px;
	padding: 0 50px;
	position: relative;
	width: 100%;
}

.App-logo-circle-zen {
	font-size: 70px;
	font-weight: 500;
}
.App-logo-circle-trails {
	font-size: 40px;
}

.App-main {
	background-color: #45a545;
	box-sizing: border-box;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	padding: 40px 0 40px 0;
	position: relative;
	width: 100%;
}

.App-mission-text {
	color: #fff;
	font-size: 30px;
	margin: 0 100px;
	max-width: 700px;
}

.App-footer {
	background-color: #45a545;
	color: #fff;
	padding: 5px;
	text-align: center;
	width: 100%;
}
